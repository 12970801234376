@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

:root {
    // Main Color
  
    --black       : #000000;
    --white       : #ffffff;
  
    --primary     : #040505;
    --pText       : #fff;
    --secondary   : #F27834;
    --sText       : #040505;
  
    --textColor   : #606060;
    --textColor2  : #B1B1B1;

    // Black Shades

    --shade1      :   #111;
    --shade5      :   #555;
    --shade6      :   #666;
    --shade12     :   #ccc;
    --shade14     :   #ededed;
  }

// Fonts

@font-face {
    font-family: FuturaLight;
    src: url(assets/font/futura-light.ttf);
}

@font-face {
    font-family: FuturaBook;
    src: url(assets/font/futura-book.ttf);
}

@font-face {
    font-family: FuturaMedium;
    src: url(assets/font/futura-medium.ttf);
}

@font-face {
    font-family: FuturaBold;
    src: url(assets/font/futura-bold.ttf);
}


html {
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color: var(--shade5) var(--shade12);
    scrollbar-width: thin;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: var(--shade12);
}
::-webkit-scrollbar-thumb {
    background: var(--shade6);
}
::-webkit-scrollbar-thumb:hover {
    background: var(--shade5);
}

a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
      &:not([href]):not([tabindex]) {
        color: var(--primary);
      }
    }
    &:not([href]):not([tabindex]) {
      color: var(--shade1);
    }
    &:focus {
      text-decoration: none;
    }
}
* {
    box-sizing: border-box;
    font-size: 16px;
    font-family: FuturaBook;
    font-weight: unset;
  
    &:focus {
      outline: none;
    }
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 768px) {
    .container {
      width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
      width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
}
@media (min-width: 1600px) {
    .container {
      width: 1440px;
    }
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: normal;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.text_center {
  text-align: center !important;
}

.text_right {
  text-align: right !important;
}

.section_text {
  display: block;
  font-size: 16px;
  font-family: FuturaBook;
  line-height: 1.6;
  color: var(--shade6);
  &.lg {
    font-size: 21px;
    @media screen and (max-width: 992px) {
      font-size: 18px;
      max-width: none;
    }
  }
  &.bold {
    font-size: 17px;
    font-family: FuturaMedium;
    color: var(--primary);
  }
}

.section_btn {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--secondary);
  margin-top: 30px;
  border: none;
  padding: 0;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    svg {
      color: var(--primary);
      width: 28px;
      height: 28px;
    }
  }
  &.sm {
    width: 50px;
    height: 50px;
    .icon {
      width: 50px;
      height: 50px;
    }
  }
  &.xs {
    width: 35px;
    height: 35px;
    .icon {
      width: 35px;
      height: 35px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.members_wrap{
  margin-top: 70px;
  .section_title {
    margin-bottom: 20px;
  }
}

.section_title {
  display: block;
  font-size: 22px;
  font-family: FuturaBold;
  color: var(--primary);
  text-transform: uppercase;
  margin-bottom: 50px;
  @media screen and (max-width: 992px) {
    margin-bottom: 30px;
  }
}

.page_title {
  display: block;
  font-size: 28px;
  text-transform: uppercase;
  font-family: FuturaBold;
  color: var(--primary);
  margin-bottom: 30px;
}

.breadcrumbs {
  display: block;
  margin-bottom: 40px;
  @media screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
  li {
    display: inline-block;
    vertical-align: middle;
    padding-right: 20px;
    padding-left: 10px;
    position: relative;
    font-size: 12px;
    color: var(--primary);
    text-transform: uppercase;
    &:first-child {
      padding-left: 0;
    }
    &::after {
      content: '\276F';
      position: absolute;
      top: 2px;
      right: 0;
    }
    &.active {
      font-size: 14px;
      &::after {
        display: none;
      }
    }
    a {
      color: var(--primary);
      font-size: 14px;
    }
  }
}

.banner {
  .owl-dots {
    display: block;
    position: absolute;
    left: 8%;
    bottom: 10%;
    margin: 0 !important;
    .owl-dot {
      &:first-child {
        span {
          margin-left: 0;
        }
      }
        span {
            width: 10px;
            height: 10px;
            background-color: var(--white);
            border-radius: 50%;
            transition: all .3s ease-in-out;
        }
        &.active {
          span {
            width: 30px;
            border-radius: 8px;
            background-color: var(--secondary);
            transition: all .3s ease-in-out;
          }
        }

        &:hover {
          span {
            background-color: var(--secondary);
            transition: all .3s ease-in-out;
          }
        }
    }
  }
}

.home_media {
  .owl-dots {
    display: block;
    position: absolute;
    right: 0;
    top: -70px;
    margin: 0 !important;
    @media screen and (max-width: 992px) {
      top: -50px;
    }
    .owl-dot {
      &:last-child {
        span {
          margin-right: 0;
        }
      }
        span {
            width: 10px;
            height: 10px;
            background-color: var(--primary);
            border-radius: 50%;
            transition: all .3s ease-in-out;
        }
        &.active {
          span {
            width: 30px;
            border-radius: 8px;
            background-color: var(--secondary);
            transition: all .3s ease-in-out;
          }
        }

        &:hover {
          span {
            background-color: var(--secondary);
            transition: all .3s ease-in-out;
          }
        }
    }
  }
}

// Page Header

.page_header {
  position: relative;
  padding: 35px 0 50px;
  @media screen and (max-width: 992px) {
    padding: 30px 0 20px;
  }
  .section_text {
    max-width: 70%;
    @media screen and (max-width: 992px) {
      max-width: none;
    }
  }
}

// Team Block

.team {
  position: relative;
  padding: 50px 0 200px;
  @media screen and (max-width: 1366px) {
    padding: 50px 0 120px;
  }
  .team_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -2px;
    @media screen and (max-width: 540px) {
      flex-direction: column;
      margin: 0;
    }
  }
  .team_col {
    display: flex;
    flex-direction: column;
    width: 20%;
    position: relative;
    overflow: hidden;
    padding: 2px;
    transition: all .5s ease-in-out;
    @media screen and (max-width: 992px) {
      width: 50%;
    }
    @media screen and (max-width: 540px) {
      width: 100%;
    }
    &:hover {
      transition: all .5s ease-in-out;
      .team_img {
        transition: all .5s ease-in-out;
        &::after {
          opacity: 1;
          transition: all .5s ease-in-out;
        }
      }
      .team_contact {
        opacity: 1;
        height: 60px;
        pointer-events: all;
        transition: all .5s ease-in-out;
      }
    }
  }
  .team_img {
    display: block;
    width: 100%;
    height: 280px;
    overflow: hidden;
    position: relative;
    transition: all .5s ease-in-out;
    @media screen and (max-width: 1366px) {
      height: 240px;
    }
    @media screen and (max-width: 992px) {
      height: 340px;
    }
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: var(--secondary);
      opacity: 0;
      z-index: 101;
      transition: all .5s ease-in-out;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(100%);
    }
  }
  .team_data {
    position: absolute;
    bottom: 25px;
    right: 0;
    left: 25px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-bottom: 2px solid var(--white);
    z-index: 102;
    .team_title {
      display: block;
      font-size: 20px;
      line-height: 20px;
      color: var(--white);
      font-family: FuturaMedium;
      margin: 0;
      text-shadow: 0 0 5px transparentize(black, 0.5);
      @media screen and (max-width: 992px) {
        font-size: 22px;
      }
    }
    .team_meta {
      display: block;
      font-size: 15px;
      color: var(--white);
      font-family: FuturaMedium;
      margin-bottom: 10px;
      text-shadow: 0 0 5px transparentize(black, 0.5);
      @media screen and (max-width: 1366px) {
        font-size: 12px;
      }
      @media screen and (max-width: 992px) {
        font-size: 16px;
      }
    }
  }
  .team_contact {
    display: block;
    height: 0;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    transition: all .5s ease-in-out;
    .cnt_link {
      display: block;
      margin-bottom: 8px;
      font-size: 15px;
      color: var(--white);
      
      svg {
        width: 18px;
        height: 18px;
        object-fit: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        color: var(--primary);
      }
    }
  }
}
.lb-data .lb-closeContainer {
  float: right !important;
  position: absolute !important;
  top: 0px !important;
  right: 25% !important;
}