@charset "UTF-8";
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --primary: #040505;
  --pText: #fff;
  --secondary: #F27834;
  --sText: #040505;
  --textColor: #606060;
  --textColor2: #B1B1B1;
  --shade1: #111;
  --shade5: #555;
  --shade6: #666;
  --shade12: #ccc;
  --shade14: #ededed;
}

@font-face {
  font-family: FuturaLight;
  src: url(./assets/font/futura-light.ttf);
}
@font-face {
  font-family: FuturaBook;
  src: url(./assets/font/futura-book.ttf);
}
@font-face {
  font-family: FuturaMedium;
  src: url(./assets/font/futura-medium.ttf);
}
@font-face {
  font-family: FuturaBold;
  src: url(./assets/font/futura-bold.ttf);
}
html {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: var(--shade5) var(--shade12);
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--shade12);
}

::-webkit-scrollbar-thumb {
  background: var(--shade6);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--shade5);
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:hover:not([href]):not([tabindex]) {
  color: var(--primary);
}
a:not([href]):not([tabindex]) {
  color: var(--shade1);
}
a:focus {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-size: 16px;
  font-family: FuturaBook;
  font-weight: unset;
}
*:focus {
  outline: none;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1600px) {
  .container {
    width: 1440px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: normal;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.text_center {
  text-align: center !important;
}

.text_right {
  text-align: right !important;
}

.section_text {
  display: block;
  font-size: 16px;
  font-family: FuturaBook;
  line-height: 1.6;
  color: var(--shade6);
}
.section_text.lg {
  font-size: 21px;
}
@media screen and (max-width: 992px) {
  .section_text.lg {
    font-size: 18px;
    max-width: none;
  }
}
.section_text.bold {
  font-size: 17px;
  font-family: FuturaMedium;
  color: var(--primary);
}

.section_btn {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--secondary);
  margin-top: 30px;
  border: none;
  padding: 0;
}
.section_btn .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
}
.section_btn .icon svg {
  color: var(--primary);
  width: 28px;
  height: 28px;
}
.section_btn.sm {
  width: 50px;
  height: 50px;
}
.section_btn.sm .icon {
  width: 50px;
  height: 50px;
}
.section_btn.xs {
  width: 35px;
  height: 35px;
}
.section_btn.xs .icon {
  width: 35px;
  height: 35px;
}
.section_btn.xs .icon svg {
  width: 20px;
  height: 20px;
}

.members_wrap {
  margin-top: 70px;
}
.members_wrap .section_title {
  margin-bottom: 20px;
}

.section_title {
  display: block;
  font-size: 22px;
  font-family: FuturaBold;
  color: var(--primary);
  text-transform: uppercase;
  margin-bottom: 50px;
}
@media screen and (max-width: 992px) {
  .section_title {
    margin-bottom: 30px;
  }
}

.page_title {
  display: block;
  font-size: 28px;
  text-transform: uppercase;
  font-family: FuturaBold;
  color: var(--primary);
  margin-bottom: 30px;
}

.breadcrumbs {
  display: block;
  margin-bottom: 40px;
}
@media screen and (max-width: 992px) {
  .breadcrumbs {
    margin-bottom: 20px;
  }
}
.breadcrumbs li {
  display: inline-block;
  vertical-align: middle;
  padding-right: 20px;
  padding-left: 10px;
  position: relative;
  font-size: 12px;
  color: var(--primary);
  text-transform: uppercase;
}
.breadcrumbs li:first-child {
  padding-left: 0;
}
.breadcrumbs li::after {
  content: "❯";
  position: absolute;
  top: 2px;
  right: 0;
}
.breadcrumbs li.active {
  font-size: 14px;
}
.breadcrumbs li.active::after {
  display: none;
}
.breadcrumbs li a {
  color: var(--primary);
  font-size: 14px;
}

.banner .owl-dots {
  display: block;
  position: absolute;
  left: 8%;
  bottom: 10%;
  margin: 0 !important;
}
.banner .owl-dots .owl-dot:first-child span {
  margin-left: 0;
}
.banner .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background-color: var(--white);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.banner .owl-dots .owl-dot.active span {
  width: 30px;
  border-radius: 8px;
  background-color: var(--secondary);
  transition: all 0.3s ease-in-out;
}
.banner .owl-dots .owl-dot:hover span {
  background-color: var(--secondary);
  transition: all 0.3s ease-in-out;
}

.home_media .owl-dots {
  display: block;
  position: absolute;
  right: 0;
  top: -70px;
  margin: 0 !important;
}
@media screen and (max-width: 992px) {
  .home_media .owl-dots {
    top: -50px;
  }
}
.home_media .owl-dots .owl-dot:last-child span {
  margin-right: 0;
}
.home_media .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.home_media .owl-dots .owl-dot.active span {
  width: 30px;
  border-radius: 8px;
  background-color: var(--secondary);
  transition: all 0.3s ease-in-out;
}
.home_media .owl-dots .owl-dot:hover span {
  background-color: var(--secondary);
  transition: all 0.3s ease-in-out;
}

.page_header {
  position: relative;
  padding: 35px 0 50px;
}
@media screen and (max-width: 992px) {
  .page_header {
    padding: 30px 0 20px;
  }
}
.page_header .section_text {
  max-width: 70%;
}
@media screen and (max-width: 992px) {
  .page_header .section_text {
    max-width: none;
  }
}

.team {
  position: relative;
  padding: 50px 0 200px;
}
@media screen and (max-width: 1366px) {
  .team {
    padding: 50px 0 120px;
  }
}
.team .team_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -2px;
}
@media screen and (max-width: 540px) {
  .team .team_row {
    flex-direction: column;
    margin: 0;
  }
}
.team .team_col {
  display: flex;
  flex-direction: column;
  width: 20%;
  position: relative;
  overflow: hidden;
  padding: 2px;
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 992px) {
  .team .team_col {
    width: 50%;
  }
}
@media screen and (max-width: 540px) {
  .team .team_col {
    width: 100%;
  }
}
.team .team_col:hover {
  transition: all 0.5s ease-in-out;
}
.team .team_col:hover .team_img {
  transition: all 0.5s ease-in-out;
}
.team .team_col:hover .team_img::after {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.team .team_col:hover .team_contact {
  opacity: 1;
  height: 60px;
  pointer-events: all;
  transition: all 0.5s ease-in-out;
}
.team .team_img {
  display: block;
  width: 100%;
  height: 280px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 1366px) {
  .team .team_img {
    height: 240px;
  }
}
@media screen and (max-width: 992px) {
  .team .team_img {
    height: 340px;
  }
}
.team .team_img::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondary);
  opacity: 0;
  z-index: 101;
  transition: all 0.5s ease-in-out;
}
.team .team_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
}
.team .team_data {
  position: absolute;
  bottom: 25px;
  right: 0;
  left: 25px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-bottom: 2px solid var(--white);
  z-index: 102;
}
.team .team_data .team_title {
  display: block;
  font-size: 20px;
  line-height: 20px;
  color: var(--white);
  font-family: FuturaMedium;
  margin: 0;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 992px) {
  .team .team_data .team_title {
    font-size: 22px;
  }
}
.team .team_data .team_meta {
  display: block;
  font-size: 15px;
  color: var(--white);
  font-family: FuturaMedium;
  margin-bottom: 10px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 1366px) {
  .team .team_data .team_meta {
    font-size: 12px;
  }
}
@media screen and (max-width: 992px) {
  .team .team_data .team_meta {
    font-size: 16px;
  }
}
.team .team_contact {
  display: block;
  height: 0;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.team .team_contact .cnt_link {
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
  color: var(--white);
}
.team .team_contact .cnt_link svg {
  width: 18px;
  height: 18px;
  object-fit: contain;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  color: var(--primary);
}

.lb-data .lb-closeContainer {
  float: right !important;
  position: absolute !important;
  top: 0px !important;
  right: 25% !important;
}